import React from 'react';
import Layout from '../components/layout';
//import Img from 'gatsby-image';
import Metatags from '../components/Metatags';
import { graphql } from 'gatsby';
import PrevNext from '../components/prevnext';
import { DiscussionEmbed } from "disqus-react";
import '../pages/pages.css';


function BlogPost(props) {
    
    const post = props.data.markdownRemark;
    const url = props.data.site.siteMetadata.siteUrl;
    const { title, description, tags } = post.frontmatter;
    const thumbnail = post.frontmatter.image.childImageSharp.resize.src;
    const { prev, next } = props.pageContext;
    const disqusShortname = "duanshu-translations";
    const disqusConfig = {
      url: `https://duanshu.me${props.location.pathname}`,
      identifier: `${props.location.pathname}`,
      title: title,
    };

    return (
        <Layout className="release">
          <Metatags
                title={title}
                description={description}
                thumbnail={url + thumbnail}
                url={url}
                pathname={props.location.pathname}
            />
          <div className="chapter">
            <h1 className="post-title">{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: post.html }}/>  
                <div>
                    <span>Tagged in</span>
                    {tags.map((tag, i) => (
                        <a href={`/${tag}`} key={i} style={{ marginLeft: "10px" }} >{tag}</a>
                    ))}
                </div>
                <PrevNext prev={prev && prev.node} next={next && next.node} />    
          </div>
          <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        </Layout>
    )
}

export default BlogPost

export const query = graphql`

  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        tags
        image {
          childImageSharp {
            resize(width: 500, height: 500) {
              src
            }
            fluid(maxWidth: 700, maxHeight: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        }

      }

    site {
      siteMetadata {
        siteUrl
      }
    }
}`